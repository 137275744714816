<template>
  <v-toolbar-title class="header-logo-box">
    <HLogoCombinationMark
      class="logo"
      :light="isTransparent"
      @click="onClick"
    />
  </v-toolbar-title>
</template>

<script>
export default {
  name: 'HHeaderLogoBox',
  components: {
    HLogoCombinationMark: () =>
      import(
        /* webpackChunkName: "svgs" */ '@/components/svgs/HLogoCombinationMark'
      )
  },
  props: {
    isTransparent: {
      type: Boolean
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
.header-logo-box::v-deep {
  max-width: 96px;
  min-width: 96px;
  width: 96px;
  & .logo {
    cursor: pointer;
  }
}
</style>
